export default {
  rooms: state => {
    return state.rooms
  },
  ratePlanUID: state => {
    return state.ratePlanUID
  },
  isDatesSelected: state => {
    return state.order.starts_at !== '' && state.order.ends_at !== ''
  },
  startsAt: state => {
    return state.order.starts_at
  },
  endsAt: state => {
    return state.order.ends_at
  },
  numberOfDays: state => {
    return state.numberOfDays
  },
  findRatePlansByRoomId: (state, getters) => (roomId) => {
    let room = state.rooms.find( data => data.id === roomId)
    if (!room) {return []}

    let ratePlans = room.rate_plans.filter(r=>r.website_published)
    let availableRatePlans = []

    for (let ratePlan of ratePlans) {
      let ratePlanAvailable = true
      for (let date in state.availability.breakdown) {
        if (state.availability.breakdown[date][room.master_id][ratePlan.master_id]['closed'] == 'true') {
          ratePlanAvailable = false
          break
        }
      }
      if (ratePlanAvailable) { availableRatePlans.push(ratePlan) }
    }
    // sort to display in order of rateplan summary
    availableRatePlans.sort(function (r1,r2) {
      let s1 = r1.summary[window.I18n.currentLocale()];
      let s2 = r2.summary[window.I18n.currentLocale()];
      if (!s1) return 1;
      if (!s2) return -1;
      return s1 == s2 ? 0 : s1 > s2 ? 1 : -1;
    });

    if (state.ratePlanID !== 0) {
      let head = availableRatePlans.find(r => r.id === state.ratePlanID)

      if (head) {
        let tail = availableRatePlans.filter(r => r.id !== state.ratePlanID)
        availableRatePlans = [head].concat(tail)
      }
    }

    // sort bookable rate plans first
    let bookableRatePlans = []
    let unbookableRatePlans = []
    availableRatePlans.forEach( (rp) => {
      let ratePlanAvailability = getters.availabilityByMasterRoomId(rp.master_room_id)
      let mappedAvailability = Object.keys(ratePlanAvailability).map((item) => ratePlanAvailability[item][rp.master_id])
      let min_stay = Math.max(...mappedAvailability.map((data) => data.min_stay ? data.min_stay : 0))
      let max_stay = Math.min(...mappedAvailability.map((data) => data.max_stay).filter(Number))
      if ((min_stay > state.numberOfDays) || (max_stay < state.numberOfDays)) unbookableRatePlans.push(rp)
      else bookableRatePlans.push(rp)
    })
    availableRatePlans = bookableRatePlans.concat(unbookableRatePlans)

    return availableRatePlans
  },
  availabilityByMasterRoomId: (state) => (roomId) => {
    let result = {}
    for (let date in state.availability.breakdown) {
      result[date] = state.availability.breakdown[date][roomId]
    }
    return result;
  },
  findRatePlanById: (state) => (id) => {
    let plan
    for (let room of state.rooms) {
      plan = room.rate_plans.find((p) => p.id == id)
      if (plan){
        break
      }
    }
    return plan
  },
  roomCountForRatePlan: (state, getters) => (data) => {
    // 1. how many allotment for this room type, minus
    // 2. how many of this room type already in the line_items
    let ratePlanId = data.ratePlanId;
    let masterRoomId = data.masterRoomId;
    let masterRatePlanId = data.masterRatePlanId;
    // total allotment for this room
    let allotment_array = [];
    let rate_plan_allotment_array = []
    for (let date in state.availability.breakdown) {
      if (data.allowYieldManagement) {
        rate_plan_allotment_array.push(state.availability.breakdown[date][masterRoomId][masterRatePlanId]['allotment'])
      }
      allotment_array.push(state.availability.breakdown[date][masterRoomId]['allotment'])
    }
    let allotment = Math.min(...allotment_array);
    // total selected count (all rate plans for this room)
    let total_room_count = state.order.line_items.filter(
      l => l.product_type === "Room" && l.master_room_id === masterRoomId
    ).length
    // remaining count available for this room
    let remaining_room_count = allotment - total_room_count
    // total count for this rate plan
    let current_rate_plan_count = state.order.line_items.filter(
      l => l.product_type === "Room" && l.variant_id === ratePlanId
    ).length

    let room_count = remaining_room_count + current_rate_plan_count;
    if (data.allowYieldManagement) {
      let rate_plan_allotment = Math.min(...rate_plan_allotment_array);
      return Math.min(rate_plan_allotment, room_count)
    } else {
      return room_count
    }
  },
  lineItemsForRatePlan: (state) => (id) => {
    return state.order.line_items.filter(item => item.variant_id == id)
  },
  markUpPrice: (state) => (data) => {
    if (data.discounted === true) {
      return parseFloat(data.price)
    } else {
      return parseFloat(data.base_price)
    }
    // skip markup for now, as the price is precalculated in airhost core with percentage already
    // if (data.percentage){
    //   result = parseFloat(data.price) * parseFloat(data.percentage) / 100.0
    // }
  },
  extraGuestPrice: (state) => (data) => {
    return Math.max(data.total_guests - data.guests_included, 0) *
      parseFloat(data.extra_guest_price) *
      parseFloat(data.percentage) * data.total_nights / 100.0
  },
  extraGuestPriceOnSeparated: (state) => (data) => {
    const percentage = parseFloat(data.percentage)
    const extra_guest_fee = parseFloat(data.extra_guest_price)
    const extra_child_price = parseFloat(data.extra_child_price)
    const extra_infant_price = parseFloat(data.extra_infant_price)

    let adults_fee = 0
    let childs_fee = 0
    let infants_fee = 0
    if (data.adult > data.guests_included) {
      adults_fee = (data.adult - data.guests_included) * extra_guest_fee * data.total_nights * percentage / 100.0
    }

    if (data.adult + data.child > data.guests_included) {
      childs_fee = Math.min((data.adult + data.child - data.guests_included), data.child) * extra_child_price * data.total_nights * percentage / 100.0
    }

    infants_fee = data.infant * extra_infant_price * data.total_nights * percentage / 100.0
    return [adults_fee, childs_fee, infants_fee]
  },
  buildLineItem: (state, getters) => (data) => {
    let result = data
    result["currency"] = state.order.currency
    result["starts_at"] = state.order.starts_at
    result["ends_at"] = state.order.ends_at
    let room_data = state.rooms.find(room => room.id === data.product_id)

    // pricing data for each date
    let availability_array = [];
    for (let date in state.availability.breakdown) {
      let availability = state.availability.breakdown[date][data.master_room_id][data.master_rate_plan_id]
      availability['date'] = date;
      availability_array.push(availability)
    }
    result["preferences"]["per_unit_rates"] = []
    availability_array.forEach( (o) => {
      result["preferences"]["per_unit_rates"].push({
        unit: "day",
        key: o.date,
        name: "per_day",
        base_price: getters.markUpPrice({
          base_price: o.base_price,
          price: o.price,
          discounted: result.discounted
        }),
        amount: getters.markUpPrice({
          base_price: o.base_price,
          price: o.price,
          discounted: result.discounted
        })
      })
      return
    })
    result["price"] = result["preferences"]["per_unit_rates"].reduce(
      (sum, item) => sum += item["amount"], 0)

    // guests and adjustments
    let master_rate_plan = room_data.rate_plans.find(r => r.id === data.variant_id)
    let guests_included = master_rate_plan.settings.guests_included || total_guests
    let total_guests = parseInt(result["preferences"].adult) + parseInt(result["preferences"].child)
    let extra_guest_fee = 0
    let extra_child_fee = 0
    let extra_infant_fee = 0
    result.adjustments = []

    const separatedFees = getters.extraGuestPriceOnSeparated({
      adult: parseInt(result["preferences"].adult) || 1,
      child: parseInt(result["preferences"].child) || 0,
      infant: parseInt(result["preferences"].infant) || 0,
      guests_included: guests_included,
      extra_guest_price: master_rate_plan.settings.extra_guest_price || 0,
      extra_child_price: master_rate_plan.settings.extra_guest_price_for_airhost_child || 0,
      extra_infant_price: master_rate_plan.settings.extra_guest_price_for_airhost_infant || 0,
      percentage: room_data.settings.percentage || 100,
      total_nights: result["preferences"]["per_unit_rates"].length
    })
    extra_guest_fee = separatedFees[0]
    extra_child_fee = separatedFees[1]
    extra_infant_fee = separatedFees[2]

    if (extra_guest_fee > 0) {
      result.adjustments.push({
        "amount": extra_guest_fee,
        "included": true,
        "name": "extra_guest_fee",
        "source_type": "RatePlan",
        "source_id": data.master_rate_plan_id
      })
      result["price"] += extra_guest_fee
    }

    if (extra_child_fee > 0) {
      result.adjustments.push({
        "amount": extra_child_fee,
        "included": true,
        "name": "extra_child_fee",
        "source_type": "RatePlan",
        "source_id": data.master_rate_plan_id
      })
      result["price"] += extra_child_fee
    }

    if (extra_infant_fee > 0) {
      result.adjustments.push({
        "amount": extra_infant_fee,
        "included": true,
        "name": "extra_infant_fee",
        "source_type": "RatePlan",
        "source_id": data.master_rate_plan_id
      })
      result["price"] += extra_infant_fee
    }

    if (room_data.cleaning_fee_to_guest){
      result.adjustments.push({
        "amount": parseFloat(room_data.cleaning_fee_to_guest),
        "included": false,
        "name": "cleaning_fee",
        "source_type": "Room",
        "source_id": room_data.id
      })
    }
    return result
  },

  disabledDates: (state) => {
    let result = []
    for(let d in state.datepickerAvailability){
      if (state.datepickerAvailability[d] <= 0){
        result.push(d)
      }
    }
    return result
  },

  datepickerAvailabilityEndDate: (state) => {
    // should keep datepickerAvailability sorted by key asc
    return Object.keys(state.datepickerAvailability).pop()
  }
}
