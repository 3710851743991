<template>
  <div
    v-on:mouseenter="mouseenter"
    v-on:mouseleave="mouseleave">
    <input
      id="hdpkr"
      type="text"
      :placeholder="placeholder"
      class="calendar-input"
      autocomplete="off"
      style="cursor:pointer;">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HotelDatepicker from 'hotel-datepicker'
import { orderModel }  from 'store/house_show/model';

export default {
  data: function() {
    return {
      dirty: false,
      hdpkr: null,
      checkinDate: null,
      checkoutDate: null,
      maxBookingWindowDays: this.$store.state.maxBookingWindowDays,
      separator: ' -> ',
      placeholder: I18n.t('houses.dates_picker.check-in') + ' -> ' + I18n.t('houses.dates_picker.check-out'),
      ycoords: null,
      datepickerAvailabilityLoaded: false,
      datepickerAvailabilityLoading: false,
      domLoadedEndsAt: moment() // used to check which date elements need to be updated
    }
  },

  computed: {
    calendarOptions: function() {
      return {
        moveBothMonths: true,
        autoClose: true,
        startDate: new Date(),
        endDate: moment().add(this.maxBookingWindowDays, 'days').toDate(),
        disabledDates: this.disabledDates,
        enableCheckout: true,
        separator: this.separator,
        onSelectRange: this.updateDateRange,
        i18n: window.I18n.t('houses.hotel_datepicker'),
        showTopbar: false,
        animationSpeed: ".2s",
        onOpenDatepicker: () => {
          this.domLoadedEndsAt = moment(this.$store.getters.datepickerAvailabilityEndDate, 'YYYY-MM-DD')
        }
      }
    },

    checkInText: function() {
      if (!!this.$store.state.order.starts_at && this.$store.state.order.starts_at !== '') {
        return moment(this.$store.state.order.starts_at).format('YYYY-MM-DD')
      } else {
        return ''
      }
    },

    checkOutText: function() {
      if (!!this.$store.state.order.ends_at && this.$store.state.order.ends_at !== '') {
        return moment(this.$store.state.order.ends_at).format('YYYY-MM-DD')
      } else {
        return ''
      }
    },

    isAllowedToSetDefaultValue: function() {
      if (!this.checkInText || !this.checkOutText) return false

      return !(this.disabledDates && this.disabledDates.some(date => {
        return moment(date).isBetween(this.checkInText, this.checkOutText, undefined, '[)')
      }))
    },

    ...mapGetters([
      'disabledDates',
      'isDatesSelected'
    ])
  },


  methods: {
    mouseenter: function () {
      if (this.hdpkr.isOpen) {
        return
      }

      if (this.hdpkr && this.ycoords != event.clientY) {
        this.hdpkr.destroy();
        this.setDatepicker();
      }
      this.ycoords = event.clientY
      this.hdpkr.open();
    },

    mouseleave: function () {
      this.hdpkr.close();
      this.guardedLoadDatepickerAvailability(); // try to continue load more datepickerAvailability
    },

    setDatepPickerDefaultValue: function() {
      if (this.isAllowedToSetDefaultValue) {
        this.hdpkr.setValue(this.checkInText + this.separator + this.checkOutText)
      }
    },

    updateDateRange: function() {
      this.checkinDate = this.hdpkr.getValue().split(this.separator)[0]
      this.checkoutDate = this.hdpkr.getValue().split(this.separator)[1]
      $.magnificPopup.close();
      this.setURL();
    },

    setURL() {
      let queryString = `start_date=${this.checkinDate}&end_date=${this.checkoutDate}`
      const roomId = (/room=(\d+)/.exec(location.search) || [])[1]
      if (roomId) queryString = `${queryString}&room=${roomId}`
      let URL = `${this.$store.state.housePath}?${queryString}`
      if (!this.isDatesSelected) {
        Turbolinks.visit(URL)
      } else {
        // fade in the loading animation
        $('[data-loader="circle-side"]').fadeIn()
        $('#preloader').fadeIn()
        // ajax to fetch availability
        let ajaxURL = `${this.$store.state.housePath}/rooms_availability?${queryString}`
        var self = this;
        let numberOfDays = moment(this.checkoutDate, 'YYYY-MM-DD').diff(moment(this.checkinDate, 'YYYY-MM-DD'), 'days');
        Rails.ajax({
          beforeSend: () => true,
          url: ajaxURL,
          type: "GET",
          dataType: 'json',
          contentType: 'application/json',
          success: function(response) {
            self.$updatePageData(self, response, numberOfDays)
            // initialize dates
            self.$store.dispatch("initializeDates", {starts_at: self.checkinDate, ends_at: self.checkoutDate})
            // replace url
            window.history.replaceState({turbolinks: true, url: URL}, '', URL);
            // fade out the loading animation
            $('[data-loader="circle-side"]').fadeOut()
            $('#preloader').fadeOut('slow')
          },
          error: function(error) {
            notification(I18n.t("error") + ': ' + I18n.t("houses.show.error_500") + error);
          }
        })
      }
    },

    setDatepicker() {
      this.hdpkr = new HotelDatepicker(document.getElementById('hdpkr'), this.calendarOptions)
      this.setDatepPickerDefaultValue()
    },

    guardedLoadDatepickerAvailability(leftClosedAt) {
      try {
        this.loadDatepickerAvailability(leftClosedAt);
      } catch (error) {
        console.error(error);
      }
    },

    loadDatepickerAvailability(leftClosedAt) {
      if (this.datepickerAvailabilityLoaded) {
        return
      }

      if (this.datepickerAvailabilityLoading) {
        return
      }
      this.datepickerAvailabilityLoading = true

      let currentEndDate = moment(this.$store.getters.datepickerAvailabilityEndDate, 'YYYY-MM-DD')
      if (!currentEndDate.isValid()) {
        currentEndDate = moment()
      }
      let maxEndDate = moment(this.calendarOptions.endDate);

      if (currentEndDate.isSameOrAfter(maxEndDate)) { // already fully loaded
        this.datepickerAvailabilityLoaded = true
        return
      }

      if (leftClosedAt) {
        if (leftClosedAt.isSameOrAfter(maxEndDate)) { // no next page
          this.datepickerAvailabilityLoaded = true
          return
        }
      } else {
        leftClosedAt = currentEndDate
      }
      let rightClosedAt = leftClosedAt.clone().add(1, 'months').endOf('day')

      // ajax to fetch simple_allots
      let queryString = `left_closed_at=${leftClosedAt.format('YYYY-MM-DD')}&right_closed_at=${rightClosedAt.format('YYYY-MM-DD')}`
      const roomId = (/room=(\d+)/.exec(location.search) || [])[1]
      if (roomId) {
        queryString = `${queryString}&room=${roomId}`
      }

      let ajaxURL = `${this.$store.state.housePath}/calendar?${queryString}`
      let self = this;
      Rails.ajax({
        beforeSend: () => true,
        url: ajaxURL,
        type: "GET",
        dataType: 'json',
        contentType: 'application/json',
        success: function (response) {
          if (response.success) {
            self.onPartiallyDatepickerAvailabilityLoaded(rightClosedAt, response.data)

            setTimeout(function () {
              self.datepickerAvailabilityLoading = false
              self.loadDatepickerAvailability(rightClosedAt) // next page
            }, 300)
          } else {
            self.datepickerAvailabilityLoaded = true // prevent infinite loop
          }
        },
        error: function (error) {
          console.error(error)
          self.datepickerAvailabilityLoaded = true // prevent infinite loop
          // Doing nothing, user can continue to operate on incomplete calendar
        }
      })
    },

    onPartiallyDatepickerAvailabilityLoaded(loadedRightClosedAt, data) {
      this.$store.commit("extendDatepickerAvailability", data)
      this.updateMonthDom(loadedRightClosedAt)
      this.domLoadedEndsAt = loadedRightClosedAt
    },

    updateMonthDom(loadedRightClosedAt) {
      this.hdpkr.disabledDates = this.disabledDates
      if (this.hdpkr.disabledDates.length > 0) {
        // update disableDates for current datepicker instance
        this.hdpkr.parseDisabledDates()

        this.onAnySelectedDateInvalid()

        if (this.hdpkr.isOpen) {
          let currentMonth1End = moment(this.hdpkr.month1).endOf('month')
          let currentMonth2End = moment(this.hdpkr.month2).endOf('month')
          if (this.domLoadedEndsAt.isBefore(currentMonth2End) && loadedRightClosedAt.isSameOrAfter(currentMonth1End)) { // month Dom is stale
            // update if loaded data can cover this month
            if (loadedRightClosedAt.isSameOrAfter(currentMonth1End)) {
              this.hdpkr.showMonth(this.hdpkr.month1, 1)
            }

            if (loadedRightClosedAt.isSameOrAfter(currentMonth2End)) {
              this.hdpkr.showMonth(this.hdpkr.month2, 2)
            }

            // Show selected days in the calendar
            this.hdpkr.showSelectedDays()

            // Disable (if needed) the prev/next buttons
            this.hdpkr.disableNextPrevButtons()
          }
        }
      }
    },

    onAnySelectedDateInvalid() {
      let start;
      let end;
      if (this.hdpkr.isOpen) {
        if (!this.hdpkr.start && !this.hdpkr.end) {
          return
        }

        start = moment(this.hdpkr.start).startOf('day')
        if (this.hdpkr.end) {
          end = moment(this.hdpkr.end).startOf('day')
        }
      } else {
        let inputValue = this.hdpkr.getValue()

        if (!inputValue) {
          return
        }
        
        let dates = inputValue.split(this.hdpkr.separator)

        start = moment(dates[0], 'YYYY-MM-DD').startOf('day')
        end = moment(dates[1], 'YYYY-MM-DD').startOf('day')
      }

      if (this.hdpkr.start && this.hdpkr.end){
        // check maxDays and minDays
        this.hdpkr.checkSelection()
      }

      if (!this.disabledDates) {
        return
      }

      let anyInvalid = false

      if (end) { // date range selected
        for (let x of this.disabledDates) {
          // end is exclusive
          if (moment(x, 'YYYY-MM-DD').isBetween(start, end, undefined, '[)')) {
            anyInvalid = true
            break
          }
        }
      } else if (start) { // selecting
        for (let x of this.disabledDates) {
          if (moment(x, 'YYYY-MM-DD').isSame(start)) {
            anyInvalid = true
            break
          }
        }
      }

      if (anyInvalid) {
        this.hdpkr.clearHovering()
        this.hdpkr.clearSelection()
      }
    }
  },

  watch: {
    checkInText() {
      this.setDatepPickerDefaultValue()
    },
    checkOutText() {
      this.setDatepPickerDefaultValue()
    }
  },

  mounted() {
    this.setDatepicker()
    this.guardedLoadDatepickerAvailability() // try to load more datepickerAvailability at initialized
  },
}
</script>

<style lang="scss">
@import 'hotel-datepicker/dist/css/hotel-datepicker.css';
#datepicker-hdpkr {
  line-height: 19px;
  width: 300px !important;
  @media (min-width: 646px) and (max-width: 991px) {
    width: 560px !important;
  }
  .datepicker__month {
    font-size: 14px;
  }
  .datepicker__months {
    &:before {
      background: transparent;
    }
    @media (max-width: 645px) {
      #month-2-hdpkr {
        display:none;
      }
    }
    @media (min-width: 646px) and (max-width: 991px) {
      &:before {
        background: #dcdcdc;
      }
    }
  }
  .datepicker__month-day--first-day-selected, .datepicker__month-day--last-day-selected {
    background-color: var(--primary) !important;
    color: #fff;
  }
  .datepicker__month-day--selected, .datepicker__month-day--hovering {
    background-color: rgba(var(--primary-text), 0.5);
    color: #fff;
  }

  .datepicker__month-day.datepicker__month-day--visibleMonth.datepicker__month-day--valid:not(.datepicker__month-day--today) {
    color: rgb(72, 76, 85);
  }

  .datepicker__month-day--today.datepicker__month-day--disabled{
    background-color: #9098aa;
  }
}
#month-1-hdpkr, #month-2-hdpkr{
  width: 100%;
  @media (min-width: 646px) and (max-width: 991px) {
    width: 50% !important;
  }
}
.calendar-input {
  width: 100%;
  padding-left:20%;
  min-height: 50px;
  @media (max-width: 991px) {
    padding-left:0;
    text-align: center;
  }
}
</style>
