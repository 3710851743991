export default {
  updateRatePlanUID(state) {
    state.ratePlanUID += 1
  },
  updateRooms(state, rooms) {
    state.rooms = rooms
  },
  updatePloicy(state, data) {
    state.rooms.forEach(room => {
      room.rate_plans.forEach(rate_plan => {
        rate_plan.policy = data[rate_plan.id]
      })
    })
  },
  updateOrderCurrency(state, currency) {
    state.order.currency = currency
  },
  updateNumberOfDays(state, days) {
    state.numberOfDays = days
  },
  updateOrder(state, order) {
    state.order = order
  },
  updateAvailability(state, availability) {
    state.availability = availability
  },
  // should keep datepickerAvailability sorted by key asc
  extendDatepickerAvailability(state, simple_allots) {
    state.datepickerAvailability = Object.assign({}, state.datepickerAvailability, simple_allots);
  },
  removeItem(state, data) {
    const index = state.order.line_items.findIndex(item => item.index == data.index)
    state.order.line_items.splice(index, 1)
    state.order.item_count = state.order.line_items.length
  },
  updateRoomBtnDisabled(state, payload) {
    state.roomBtnDisabled[payload.roomId] = payload.value
  },
  addItem(state, data) {
    state.order.line_items.push(data)
    state.order.item_count = state.order.line_items.length
  },
  editItem(state, data) {
    const item_index = state.order.line_items.findIndex((item) => item.index == data.index)
    state.order.line_items.splice(item_index, 1, data)
  },
  updateStartsAt(state, data){
    state.order["starts_at"] = moment(data).format("YYYY-MM-DD")
    state.order.line_items.forEach( (item) => {
      item["starts_at"] = state.order["starts_at"]
      return
    })
  },
  updateEndsAt(state, data){
    state.order["ends_at"] = moment(data).format("YYYY-MM-DD")
  },
  updateSummary(state){
    state.rooms.forEach( (room) => {
      delete state.summary[room.summary[window.I18n.currentLocale()]]
      let summary = state.order.line_items.filter(
        (item) => item.product_id === room.id
      ).map( (item) => {
        return{
          name: item.variant_name,
          price: item.price,
          adult: item.preferences.adult,
          child: item.preferences.child,
          infant: item.preferences.infant,
          id: item.index,
          adjustments: item.adjustments
        }
      })
      if(summary.length > 0){
        Vue.set(state.summary, room.summary[window.I18n.currentLocale()], summary)
      }
      return
    })
    state.order.total = state.order.line_items.reduce(
      (sum, item) => sum += item["price"] + item.adjustments.reduce(
        (sum, adjustment) => sum += ['extra_guest_fee', 'extra_child_fee', 'extra_infant_fee'].includes(adjustment.name) ? 0 : adjustment["amount"], 0
      ), 0
    )
  }
}